<template>
  <v-card class="mx-auto" v-if="users.length > 0">
    <v-carousel :continuous="true" hide-delimiter-background hide-delimiters height="332px">
      <v-carousel-item
        v-for="(item, i) in users"
        :key="i"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <v-card-title
          >{{ $t("app.newRegistrations") }} <v-spacer></v-spacer>
          <v-btn outlined small :to="{ name: 'list-users' }">{{
            $t("app.checkAll")
          }}</v-btn></v-card-title
        >
        <users-card :item="item" :limit-text="limitText" :icon="icon" :namespace="namespace" />
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "UsersCarousel",
  computed: {
    ...mapGetters({
      loading: "options/loading",
      users: "users/latest"
    })
  },
  components: { UsersCard: () => import("@/components/core/cards/UserCard") },
  created() {
    this.getData();
  },
  data() {
    return {
      limitText: "90",
      icon: "mdi-account",
      namespace: "users"
    };
  },
  mounted() {},
  methods: {
    async getData() {
      await this.$store.dispatch("users/getLatest");
    }
  }
};
</script>
